"use client";
import React from "react";
import {Link} from "react-router-dom";

const TopGames = ({games}) => {
    return (
        <div className="h-[230px]">
            <p className={`text-lg font-bold leading-6 sm:pl-0 pl-2 pt-4 ${process.env.REACT_APP_THEME === "dark" ? "text-white" : "text-black"}`}>
                Top 10 Games Played Worldwide
            </p>
            <div className="flex flex-row overflow-x-auto space-x-4 pb-4" style={{
                scrollbarWidth: "none"
            }}>
                {games.map((game, index) => (
                    <div
                        className="flex items-center rounded-lg p-4 flex-shrink-0"
                        key={index}
                    >
                        <div className="flex-shrink-0">
                            <img
                                src={`/top-10-games/${index + 1}.svg`}
                                alt={`Rank ${index + 1}`}
                                className={"h-[150px]"}
                            />
                        </div>
                        <div className="flex flex-col items-center ">
                            <div className="relative">
                                <Link to={`${game.slug}`} state={{from: '/'}}>
                                    <img
                                        src={game.property.thumbnail}
                                        alt={game.property.thumbnail}
                                        className="rounded-lg object-cover h-[150px] w-[150px]"
                                    />
                                </Link>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TopGames;
