import React, {createElement, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import {useDispatch, useSelector} from 'react-redux/es'
import {setAllCategory} from '../../../store/ViewAll/categorySlice'
import {SkeletonCards} from '../../component/Common/SkeletonCards'
import {
    useUserAllCategoryMutation,
    useUserAllCategoryIdMutation,
} from '../../../store/user/actions'
import {sendAnalytics} from '../../../utils/analytics'
import {Helmet} from 'react-helmet'
import {useScroll} from '../../../providers/scrollProvider'
import {CategoryComponent} from '../../component/Home/CategoryComponent'
import TimepassInfoBanner from "../../component/Banner/TimepassInfoBanner";
import TopGames from "../../component/Game/TopGames";

const Home = () => {
    const dispatch = useDispatch()
    const games = useSelector(state => state.games)
    const loginState = useSelector(state => state.login)
    const allCategory = useSelector(state => state.allCategory)
    const location = useLocation()
    const [userAllCategoryId] = useUserAllCategoryIdMutation()
    const [userAllCategory] = useUserAllCategoryMutation()
    const {
        scrollHomepageForNotLoggedInUser,
        setScrollHomepageForNotLoggedInUser,
        setScrollHomePageForLoggedInUser,
    } = useScroll()

    const [firstTimeLoading, setFirstTimeLoading] = useState(true);


    const getAllCategoryId = async () => {
        try {
            const res = await userAllCategoryId().unwrap()
            const allCategories = res?.categories?.reduce(
                (acc, val) => (acc ? [...acc, val?.uuid] : [val?.uuid]),
                []
            )
            setFirstTimeLoading(false)
            return allCategories
        } catch (error) {
            console.log(error)
        }
    }

    const fetchSingleCategory = async (categoryId, page) => {
        try {
            const res = await userAllCategory({
                categoryId,
                page,
            }).unwrap()
            return res
        } catch (error) {
            console.error(error)
        }
    }

    const [isAllCategoriesLoading, setIsAllCategoriesLoading] =
        useState(true)

    const fetchCategories = async categories => {
        setIsAllCategoriesLoading(true)
        for (const categoryId of categories) {
            try {
                const res = await fetchSingleCategory(categoryId, 1)
                if (res?.results?.games?.length > 0) {
                    dispatch(setAllCategory(res))
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsAllCategoriesLoading(false)
    }

    useEffect(() => {
        if (
            location?.search?.split('=')[0]?.substring(1) === 'userId' &&
            location?.search?.split('=')[1]
        ) {
            const id = location?.search?.split('=')[1]
            sendAnalytics('identify', {}, id, ['mixpanel'])
        }
    }, [location])

    const handleAllGamesClicked = async (game_name, uid, updatedAt) => {
        const properties = {
            GAME_NAME: game_name,
            GAME_FROM: 'Home',
            GAME_UUID: uid,
            SEGMENT_NAME: 'All Games',
        }
        sendAnalytics('track', properties, 'Play Game', ['mixpanel', 'gtag'])
    }

    useEffect(() => {
        getAllCategoryId().then(res => {
            if (res && allCategory?.data?.length === 0) {
                fetchCategories(res)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (allCategory) {
            setIsAllCategoriesLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleScrollForNotLoggedInUser = () =>
        setScrollHomepageForNotLoggedInUser(window.scrollY)
    const handleScrollForLoggedInUser = () =>
        setScrollHomePageForLoggedInUser(window.scrollY)

    useEffect(() => {
        if (!loginState.accessToken) {
            window.scrollTo(0, scrollHomepageForNotLoggedInUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, loginState])

    useEffect(() => {
        if (
            !games?.isLoading &&
            games?.data?.results?.length > 0 &&
            !loginState.accessToken
        ) {
            document.addEventListener(
                'scroll',
                handleScrollForNotLoggedInUser
            )
        }
        return () =>
            document.removeEventListener(
                'scroll',
                handleScrollForNotLoggedInUser
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, loginState])

    useEffect(() => {
        if (
            !games?.isLoading &&
            games?.data?.results?.length > 0 &&
            !!loginState.accessToken
        ) {
            document.addEventListener('scroll', handleScrollForLoggedInUser)
        }
        return () =>
            document.removeEventListener(
                'scroll',
                handleScrollForLoggedInUser
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games, loginState])

    return (
        <div className={`${process.env.REACT_APP_THEME === "dark" ? "bg-black" : "bg-white"}`}>
            {process.env.REACT_APP_ENABLE_HEADER === "true" &&
                <header
                    className={`bg-black py-2 px-4 flex items-center justify-between shadow-md ${process.env.REACT_APP_THEME === "dark" ? "bg-black" : "bg-white"}`}>
                    <div className="flex items-center space-x-3 mx-auto">
                        <img
                            src={`/${process.env.REACT_APP_BRAND_LOGO}.png`}
                            alt="Brand Logo"
                            className="h-12 w-auto object-contain rounded-xl"
                        />
                    </div>
                </header>}

            <div
                id='gamecon'
                className='max-w-[960px] mx-auto sm:px-6 lg:px-8'>
                {firstTimeLoading && (
                    <>
                        <div className='py-4'>
                            <SkeletonCards/>
                        </div>
                    </>
                )}
                {isAllCategoriesLoading ? (
                    <>
                        <div className='py-4'>
                            <SkeletonCards/>
                        </div>
                    </>
                ) : (
                    <>
                        <Helmet>
                            <title>{process.env.REACT_APP_TITLE}</title>
                            <meta
                                name='description'
                                content={process.env.REACT_APP_META_CONTENT}
                            />
                        </Helmet>
                        <div className='py-4'>
                            {allCategory?.data?.map(data => {
                                    if (data.results.name === "Top 10 Games") {
                                        return <div>
                                            {createElement(TopGames, {
                                                games: data?.results.games
                                            })}
                                        </div>
                                    }
                                    return createElement(CategoryComponent, {
                                        key: data?.results?.uuid,
                                        games: data?.results,
                                        categoryId: data?.results?.uuid,
                                        totalpages: allCategory?.totalpages,
                                        fetchSingleCategory: fetchSingleCategory,
                                        handleAllGamesClicked: handleAllGamesClicked,
                                    })
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
            {process.env.REACT_APP_ENABLE_FOOTER === "true" &&
                <TimepassInfoBanner/>
            }

        </div>
    )
}
export default Home
