const sendToMixpanel = (event, properties = {}, action = null) => {
    if (process.env.REACT_APP_MIXPANEL_ENABLED !== "true" && process.env.REACT_APP_MIXPANEL_API_KEY) {
        return
    }
    switch (event) {
        case 'track':
            window.mixpanel.track(action, properties)
            break
        case 'reset':
            window.mixpanel.reset()
            localStorage.removeItem('mixpanelId')
            break
        case 'identify':
            window.mixpanel.identify(action)
            localStorage.setItem('mixpanelId', action)
            break
        case 'time_event':
            window.mixpanel.time_event(action)
            break
        default:
            return
    }
}

const sendToMoengage = (event, properties = {}, action = null) => {
    switch (event) {
        case 'track':
            window.Moengage.track_event(action, properties)
            break
        case 'reset':
            window.Moengage.destroy_session()
            localStorage.removeItem('mixpanelId')
            break
        case 'identify':
            window.Moengage.update_unique_user_id(action)
            localStorage.setItem('mixpanelId', action)
            break
        default:
            return
    }
}

const configureAnalytics = (event, properties, action, company) => {
    switch (company) {
        case 'moengage':
            sendToMoengage(event, properties, action)
            break
        case 'mixpanel':
            sendToMixpanel(event, properties, action)
            break
        case 'gtag':
            if (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") {
                window.gtag('event', action, properties)
            }
        default:
            return
    }
}

const commonProperties = {
    CLIENT_ID: `${process.env.REACT_APP_CLIENT_ID}`,
    CLIENT_NAME: `${process.env.REACT_APP_CLIENT_NAME}`,
}

export const sendAnalytics = (
    event,
    properties,
    action,
    eventToCompany
) => {
    const allProperties = {...properties, ...commonProperties}
    eventToCompany.forEach(company => {
        configureAnalytics(event, allProperties, action, company)
    })
}
